<template>
    <div class="mx-4 my-4 md:mx-8">
      <Breadcrumb :links="breadcrumb" />
      <br />
      <h1 class="text-3xl mb-5">Relatório de envio clientes</h1>
      <form class="mb-2">
        <div class="grid grid-cols-12 gap-3">
          <div class="col-span-12 md:col-span-2">
            <label for="de" class="mt-2 block text-sm font-medium">A partir de</label>
            <input
              v-model="form.de"
              type="datetime-local"
              name="de"
              id="de"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div class="col-span-12 md:col-span-2">
            <label for="ate" class="mt-2 block text-sm font-medium">Até</label>
            <input
              v-model="form.ate"
              type="datetime-local"
              name="ate"
              id="ate"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div class="col-span-12 md:col-span-3 xl:col-span-2">
            <button
              @click="start"
              type="button"
              class="md:mt-9 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
            >
              <span class="inline-block mr-2">Gerar relatório</span>
            </button>
          </div>
        </div>
      </form>
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Cliente
                    </th>

                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Produto
                    </th>

                    <th
                      v-if="novaVida"
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Rota
                    </th>

                    <th
                      v-if="novaVida"
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Cobrança
                    </th>
  
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ novaVida ? 'Envio Massa / Enviados' : 'Envio Massa'}}
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ novaVida ? 'Conversas / Respostas' : 'Conversas Geradas'}}
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Operadores
                    </th>
                    <th
                      v-if="!novaVida"
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Validação
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="el in data" :key="el._id">
                    <td class="text-sm font-normal 2xl:text-base px-2 py-3">
                      {{ el.clienteNome }} {{ novaVida ? '' : el.clienteEmail }}
                    </td>
                    <td class="text-sm font-normal 2xl:text-base px-2 py-3">
                      {{ el.produto }}
                    </td>
                    <td v-if="novaVida" class="text-sm font-normal 2xl:text-base px-2 py-3">
                      {{ el.rota }}
                    </td>
                    <td v-if="novaVida" class="text-sm font-normal 2xl:text-base px-2 py-3">
                      {{ el.tipoCobranca }}
                    </td>
                    <td class="text-sm font-medium 2xl:text-base px-2 py-3">
                      {{ el.entregues_enviados }}
                    </td>
                    <td class="text-sm font-medium 2xl:text-base px-2 py-3">
                      {{ el.conversas_retornos }}
                    </td>
                    <td class="text-sm font-medium 2xl:text-base px-2 py-3">
                      {{ el.qtdOperador }}
                    </td>
                    <td v-if="!novaVida" class="text-sm font-medium 2xl:text-base px-2 py-3">
                      {{ el.validacao }}
                    </td>
                    <td class="text-sm font-semibold text-green-700 2xl:text-base px-2 py-3">
                      {{ el.total }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  import Breadcrumb from "../../components/Breadcrumbs.vue";
  
  export default {
    components: {
      Breadcrumb,
    },
    data() {
      return {
        breadcrumb: [{ url: "/relatorios/relatorio-envio-clientes", value: "Relatório de envio Clientes" }],
        novaVida: false,
        route: "relatorio-envio",
        moment: moment,
        data: [],
        form: {
          de: moment().startOf("M").format("YYYY-MM-DDTHH:mm"),
          ate: moment().endOf("M").format("YYYY-MM-DDTHH:mm"),
        },
        totalEnvios: 0,
        totalEnviados: 0,
        totalNaoEnviados: 0,
      };
    },
    methods: {
      async start() {
        const url = this.$store.state.user._id.toString() === '626a87fec9cf2abcd1265acf' ? '/relatorio-trestto' : '/relatorio-novavida';
        this.novaVida = this.$store.state.user._id.toString() === '626a87fec9cf2abcd1265acf' ? false : true;
        
        const listReq = await this.$http.get(`${url}?de=${moment(this.form.de).startOf('M').format("YYYY-MM-DDTHH:mm")}&ate=${moment(this.form.ate).endOf('M').format("YYYY-MM-DDTHH:mm")}`);
        this.data = listReq.data.data;
      },
    },
    async beforeMount() {
      this.start();
    },
  };
  </script>
  